/* -Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98 */
/* -Spacyng System (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

/* rem and em do not depend on html font-size in media queries. 
Instead, 1rem=1em=16px */

/*******************************/
/* BELOW 1344px (Smaller Desktops)  */
/*******************************/

@media (max-width: 84em) {
  .hero {
    max-width: 120rem;
  }
}
/*******************************/
/* BELOW 1110px (Landscape Tablets)  */
/*******************************/

@media (max-width: 70em) {
  .service-title {
    font-size: 2rem;
    padding-bottom: 0.8rem;
  }
  .service-begining {
    font-size: 1.6rem;
  }
  .service-duration {
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
  }
  .service-icon {
    font-size: 1.6rem;
  }
  .service-item {
    font-size: 1.6rem;
  }

  .feature-icon {
    height: 3rem;
    width: 3rem;
  }

  .feature-title {
    font-size: 1.4rem;
  }

  .feature-description {
    font-size: 1.2rem;
  }

  a.btn.btn-services {
    font-size: 1.6rem;
  }
  .cta-form label {
    font-size: 1.6rem;
  }

  .cta-form input,
  select {
    font-size: 1.6rem;
  }
}

/*******************************/
/* BELOW 970px (Tablets)  */
/*******************************/
@media (max-width: 61em) {
  .nav-list {
    gap: 2.4rem;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .btn:link,
  .btn:visited {
    font-size: 1.8rem;
    padding: 1.2rem 2.4rem;
  }
  .hero-title {
    font-size: 3.6rem;
  }
  .hero-text {
    font-size: 1.6rem;
    line-height: 1.2;
  }

  .heading-secondary {
    font-size: 3rem;
  }

  .grid--2-cols {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 4.8rem;
  }

  .margin-bottom-sm {
    margin-bottom: 4.8rem;
  }

  .service-plan {
    width: 50%;
    padding: 4.8rem 1.2rem;
    justify-self: center;
  }

  .curso-paralelo::before {
    top: 8%;
    right: -9%;
    padding: 0.4rem 3.2rem;
    font-size: 1.4rem;
  }

  .feature-icon {
    height: 4rem;
    width: 4rem;
    color: #1971c2;
    background-color: #e7f5ff;
    padding: 1.6rem;
    border-radius: 100%;
  }

  .feature-title {
    margin-top: 1.2rem;
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
    font-weight: 700;
  }

  .feature-description {
    font-size: 1.4rem;
  }

  .cta-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .join-us-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

/*******************************/
/* BELOW 800px (small-Tablets)  */
/*******************************/

@media (max-width: 50em) {
  .nav-list {
    gap: 1.6rem;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .btn:link,
  .btn:visited {
    font-size: 1.8rem;
    padding: 1.4rem 2.8rem;
  }

  .hero {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    row-gap: 0rem;
  }
  .img-text-box {
    grid-row: 1;
  }

  .img-hero-box {
    grid-row: 2;
    width: 75%;
    height: 75%;
  }
  .hero-title {
    font-size: 3rem;
  }

  .hero-text {
    font-size: 1.8rem;
    line-height: 1.2;
    margin-bottom: 2.4rem;
  }

  .heading-secondary {
    font-size: 2.4rem;
  }

  .curso-paralelo::before {
    top: 8%;
    right: -11%;
    padding: 0.4rem 3.2rem;
    font-size: 1.4rem;
  }

  .footer-title {
    font-size: 1.4rem;
  }

  .media-icon {
    height: 2rem;
    width: 2rem;
  }

  .contact-icon {
    height: 2rem;
    width: 2rem;
  }

  .copyright {
    font-size: 1rem;
  }

  .contact-item {
    font-size: 1rem;
  }

  .company-item {
    font-size: 1rem;
  }
}
/*******************************/
/* BELOW 672px (Phones)  */
/*******************************/

@media (max-width: 42em) {
  .service-plan {
    width: 65%;
    padding: 4.8rem 1.2rem;
    justify-self: center;
  }

  .testimonial {
    margin-left: 2rem;
    display: flex;
    align-items: center;
  }

  .cta-form label {
    font-size: 1.2rem;
  }

  .cta-form input,
  select {
    font-size: 1.2rem;
  }

  .join-us-form-box {
    font-size: 1.4rem;
  }

  .footer-grid {
    gap: 2.4rem;
  }

  /*////////// MOBILE-NAV /////////*/

  .btn-mobile-nav {
    display: block;
    z-index: 999;
  }

  .main-nav {
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    /* relative to header */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    /* efecto de que venga desde la derecha  */
    transition: all 2 ease-in-out;
    transform: translateX(100%);

    /* blur effect in mobile nav, webkit for safari, it doesn't work for firefox*/
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

    /* display none doesn't allow animations
    display: none; */
    /* 1)hide element visually */
    opacity: 0;
    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;
    /* 3)Hide it from screen readers */
    visibility: hidden;
  }

  .mobile-nav-visible .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .main-nav-list {
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 2.4rem;
  }

  .mobile-nav-visible .mobile-nav-icon[name="close-outline"] {
    display: block;
  }

  .mobile-nav-visible .mobile-nav-icon[name="menu-outline"] {
    display: none;
  }

  .slider-testimonial {
    grid-template-rows: 1;
  }

  .dot-slider-testimonials {
    /* display none doesn't allow animations
    display: none; */
    /* 1)hide element visually */
    opacity: 0;
    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;
    /* 3)Hide it from screen readers */
    visibility: hidden;
    display: none;
  }

  .arrow-left-slider-testimonials {
    /* Arrow slider height: 185px -> 92.5px -> 11.56rem */
    position: absolute;
    top: 92.5px;
  }
  .arrow-right-slider-testimonials {
    /* Arrow slider height: 185px -> 92.5px -> 11.56rem */
    position: absolute;
    top: 92.5px;
  }
}

/*******************************/
/* BELOW 576px (Phones)  */
/*******************************/
@media (max-width: 36em) {
  .container {
    padding: 0 1.6rem;
  }

  .hero {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .heading-secondary {
    font-size: 1.8rem;
  }

  .subheading {
    font-size: 1.2rem;
  }
  .service-plan {
    width: 85%;
  }

  .form-box-cta {
    padding: 2.4rem 1.2rem;
  }

  .cta-text {
    font-size: 1.4rem;
  }

  .cta-form label {
    font-size: 1rem;
  }

  .cta-form input,
  select {
    font-size: 1.4rem;
  }

  .cta-form input::placeholder {
    /* texto de ejemplo en los campos input */
    font-size: 1.4rem;
  }

  .testimonial {
    margin-left: 2.8rem;
  }

  blockquote {
    font-size: 1.4rem;
  }

  .join-us {
    padding: 4.8rem 0;
  }

  .join-us-form-box {
    padding: 2.4rem 1.2rem;
  }

  .join-us-form input::placeholder,
  .join-us-form textarea::placeholder {
    font-size: 1.4rem;
  }

  footer {
    padding: 4.8rem 0;
  }
  .footer-grid {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0rem;
    column-gap: 0rem;
  }

  .footer-logo {
    grid-row: 2;
    grid-column: 2;
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
  }

  .footer-title {
    font-size: 1.4rem;
    margin-bottom: 1.4rem;
  }

  .media-icon {
    height: 1.4rem;
    width: 1.4rem;
  }

  .contact-icon {
    height: 1.2rem;
    width: 1.2rem;
  }

  .copyright {
    font-size: 1rem;
  }

  .contact-item {
    font-size: 1.2rem;
  }

  .company-item {
    font-size: 1.2rem;
  }
}

/*******************************/
/* BELOW 408px (Phones)  */
/*******************************/

@media (max-width: 25.5em) {
  .hero-title {
    font-size: 2.8rem;
  }
  .hero-text {
    font-size: 1.6rem;
    margin-bottom: 2.4rem;
  }

  .btn:link,
  .btn:visited {
    font-size: 1.6rem;
    padding: 0.8rem 1.6rem;
    margin-bottom: 3.6rem;
  }

  .img-hero-box {
    display: block;
    width: 100%;
    height: 100%;
    margin-bottom: 0rem;
  }
  */ .service-plan {
    width: 95%;
  }

  .feature-grid-slider .feature-icon {
    height: 5rem;
    width: 5rem;
    color: #1971c2;
    background-color: #e7f5ff;
    padding: 1.4rem;
    border-radius: 100%;
  }

  .feature-grid-slider .feature-title {
    margin-top: 1rem;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }

  .feature-grid-slider .feature-description {
    font-size: 1.6rem;
  }

  .slider {
    display: grid;
    grid-template-rows: 19fr 1fr;
    gap: 3rem;
  }
}
